import {NavBarItemModel} from '@core/models/component-models/navbar.model';

export const navbarItems: NavBarItemModel[] = [
  {
    label: 'NAVBAR.BINS.MAIN',
    image: 'assets/header-icons/bins.svg',
    permission: 'BINS',
    items: [
      {
        routerLink: '/bins/waste-types',
        label: 'NAVBAR.BINS.WASTE_TYPES',
      },
      {
        routerLink: '/bins/bin-types',
        label: 'NAVBAR.BINS.BIN_TYPES',
      },
    ],
  },
  {
    label: 'NAVBAR.LOCATIONS.MAIN',
    image: 'assets/header-icons/locations.svg',
    permission: 'LOCATIONS',
    items: [
      {
        routerLink: '/locations/location-types',
        label: 'NAVBAR.LOCATIONS.LOCATION_TYPES',
      }
    ]
  },
  {
    label: 'NAVBAR.SETTINGS.MAIN',
    image: 'assets/header-icons/settings.svg',
    permission: 'SETTINGS',
    items: [
      {
        routerLink: '/settings/organization',
        label: 'NAVBAR.SETTINGS.ORGANIZATION',
      },
      {
        routerLink: '/settings/access-branches',
        label: 'NAVBAR.SETTINGS.ACCESS_BRANCHES',
      },
      {
        routerLink: '/settings/access_roles',
        label: 'NAVBAR.SETTINGS.ACCESS_ROLES',
      }
    ],
  },
  {
    label: 'NAVBAR.EMPLOYEES.MAIN',
    image: 'assets/header-icons/employee.svg',
    permission: 'EMPLOYEES',
    items: [
      {
        routerLink: '/employees/employees',
        label: 'NAVBAR.EMPLOYEES.EMPLOYEES',
      },
      {
        routerLink: '/employees/positions',
        label: 'NAVBAR.EMPLOYEES.POSITIONS',
      }
    ],
  },
  {
    label: 'NAVBAR.VEHICLES.MAIN',
    image: 'assets/header-icons/vehicles.svg',
    permission: 'VEHICLES',
    items: [
      {
        routerLink: '/vehicles/fleet',
        label: 'NAVBAR.VEHICLES.FLEET',
      },
      {
        routerLink: '/vehicles/vehicle-types',
        label: 'NAVBAR.VEHICLES.VEHICLE_TYPES',
      },
      {
        routerLink: '/vehicles/fuel-types',
        label: 'NAVBAR.VEHICLES.FUEL_TYPES',
      }
    ],
  }
];
