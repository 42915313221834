import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID,} from '@angular/core';
import {NavigationStart, Router, RouterOutlet} from '@angular/router';
import {NavbarComponent} from './components/navbar/navbar.component';
import {ProfileDropdownComponent} from './components/profile-dropdown/profile-dropdown.component';
import {navbarItems} from './app.props';
import {I18nService} from '@core/services/i18n/i18n.service';
import {UtilsService} from "@core/services/utils.service";
import {ToastModule} from "primeng/toast";
import {isPlatformBrowser} from "@angular/common";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NavbarComponent, ProfileDropdownComponent, ToastModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  navbarItems = navbarItems;
  isLoginPage!: boolean;
  subscription: Subscription = new Subscription();

  constructor(private i18n: I18nService,
              private utilsService: UtilsService,
              private router: Router,
              @Inject(PLATFORM_ID) private readonly platformId: Object) {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.i18n.setUpLanguages();
    if (isPlatformBrowser(this.platformId)) {
      const body = document.getElementsByTagName('body')[0]
      this.i18n.onLangChange().subscribe((res) => {
        body.style.direction = res.lang === 'ar' ? 'rtl' : 'ltr'
      })
    }
    this.utilsService.setPageTitle();
    this.checkIfLoginPage();
  }

  checkIfLoginPage(): void {
    this.subscription.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          this.isLoginPage = event.url === "/login";
        }
      })
    );
  }
}
