import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {provideClientHydration, withHttpTransferCacheOptions} from '@angular/platform-browser';
import {HttpClient, provideHttpClient, withFetch, withInterceptors,} from '@angular/common/http';
import {provideAnimations} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpLoaderFactory} from './translate-http-loader';
import {CookieService} from "ngx-cookie-service";
import {tokenInterceptor} from "@core/interceptors/token.interceptor";
import {MessageService} from "primeng/api";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(withHttpTransferCacheOptions(
      {
        includeHeaders: ['X-Total-Count']
      }
    )),
    provideAnimations(),
    MessageService,
    CookieService,
    provideHttpClient(withInterceptors([tokenInterceptor]), withFetch()),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      })
    ),
  ],
};
