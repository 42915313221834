import {Routes} from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/home/home.component').then((m) => m.HomeComponent),
  },
  {
    path: 'demo',
    loadComponent: () =>
      import('./pages/demo/demo.component').then((m) => m.DemoComponent),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./pages/settings/settings.module').then((m) => m.SettingsModule)
  },
  {
    path: 'bins',
    loadChildren: () =>
      import('./pages/bins/bins.module').then((m) => m.BinsModule),
  },
  {
    path: 'employees',
    loadChildren: () => import('./pages/employees/employees.module').then(m => m.EmployeesModule)
  },
  {
    path: 'vehicles',
    loadChildren: () => import('./pages/vehicles/vehicles.module').then(m => m.VehiclesModule)
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: 'locations',
    loadChildren: () => import('./pages/locations/locations.module').then(m => m.LocationsModule)
  },
  {
    path: 'bins',
    loadChildren: () => import('./pages/bins/bins.module').then(m => m.BinsModule)
  },
  {
    path: '**',
    redirectTo: '',
  }
];
