import {HttpErrorResponse, HttpInterceptorFn} from '@angular/common/http';
import {inject, PLATFORM_ID} from "@angular/core";
import {AuthenticationService} from "@core/services/authentication.service";
import {Router} from "@angular/router";
import {catchError, EMPTY, from, switchMap} from "rxjs";
import {isPlatformBrowser} from "@angular/common";


export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authenticationService = inject(AuthenticationService);
  const router = inject(Router);
  const platformId = inject(PLATFORM_ID);
  if (req.url.includes("/api/") && !req.url.includes("/api/auth") && isPlatformBrowser(platformId)) {
    if (!authenticationService.isAccessTokenValid()) {
      return from(authenticationService.refresh()).pipe(
        switchMap(() => next(req)),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            return from(router.navigate(['/login'])).pipe(switchMap(() => EMPTY));
          }
          return EMPTY;
        })
      );
    }
  }
  return next(req);
};
