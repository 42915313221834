@if (items.length) {
  <div [ngClass]="{ 'menubar-container': isMainItem }">
    <p-menubar [styleClass]="styleClass" [model]="items" [autoDisplay]="false">
      <ng-template pTemplate="start">
        <span class="pi pi-home home-icon" (click)="router.navigate([''])"></span>
      </ng-template>
      <ng-template pTemplate="item" let-item>
        <div
          class="menu-item p-2"
          (click)="onItemClick($event, item)"
          (keypress)="$event.stopPropagation()"
        >
          @if (item.image) {
            <img class="w-6 h-6" [src]="item.image" alt="icon"/>
          }
          @if (item.label) {
            <span class="text-sm">{{ item.label | translate }}</span>
          }
        </div>
      </ng-template>
      <ng-content></ng-content>
    </p-menubar>
  </div>
}
