import {Component, Input, ViewEncapsulation} from '@angular/core';
import {NavBarItemModel} from '@core/models/component-models/navbar.model';
import {MenubarModule} from 'primeng/menubar';
import {CommonModule} from '@angular/common';
import {MultiCheckboxComponent} from '@shared/checkbox/multi-checkbox/multi-checkbox.component';
import {RadioButtonComponent} from '@shared/radio-button/radio-button.component';
import {Router} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    MenubarModule,
    CommonModule,
    MultiCheckboxComponent,
    RadioButtonComponent,
    TranslateModule,
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent {
  @Input() items: NavBarItemModel[] = [];
  @Input() isMainItem: boolean = false;
  @Input() styleClass: string = '';

  constructor(public router: Router) {
  }

  onItemClick(event: Event, item: NavBarItemModel) {
    if (event.defaultPrevented) {
      event.stopPropagation();
    }
    if (item.routerLink) {
      this.router.navigate([item.routerLink]);
    }
  }
}
